import { useMemo } from 'react';
import { useRouter } from 'next/navigation';

import { ApiMethods } from '@/constants/api';
import { KanbanCardType } from '@/interfaces/kanban-board';
import { Course, CourseModule } from '@/interfaces/explore';
import { MODULE_PROGRESS_STATUS, UserCourseModule } from '@/interfaces/user';
import { addCourseToUser } from '@/server-actions/explore-page';
import { addRemainingCourseModules, updateCourseModuleStatus } from '@/server-actions/kanban-board';
import { useNotification } from '@/providers/notification-context';
import { createHeadersJson } from '@/services/request.service';

import { useApi } from './use-api';
import { useWindowDimensions } from './use-window-dimensions';

const headers = createHeadersJson();

export const useCourseActions = (userCourses: Course[], userModules: UserCourseModule[], course: Course) => {
  const { sendRequest } = useApi();
  const { openSuccessNotification } = useNotification();

  const router = useRouter();
  const { isSmallMobile } = useWindowDimensions();

  const isCourseAdded = useMemo(
    () => userCourses?.some((userCourse) => userCourse.courseId === course.courseId),
    [course.courseId, userCourses],
  );

  const isAnyCourseModuleAdded = useMemo(
    () => userModules.some((userModule) => userModule.courseId === course.courseId),
    [course.courseId, userModules],
  );

  const openModule = (chosenModule: CourseModule) => {
    router.push(`/module/${chosenModule.moduleId}?isAssignment=false&courseId=${course.courseId}`);
  };

  const getSummativeAssessment = async () => {
    return await sendRequest(
      'sum-assessment/get-sum-assessment',
      ApiMethods.POST,
      JSON.stringify({ id: course.courseId, type: KanbanCardType.COURSE_ASSESSMENT }),
      headers,
    );
  };

  const openSummativeAssessment = () => {
    router.push(`/assessment-quiz?courseId=${course.courseId}`);
  };

  const getMissingModules = () => {
    const targetCourse = userCourses?.find((uCourse) => uCourse.courseId === course.courseId);

    return targetCourse?.modules.filter((module) => {
      return !targetCourse?.addedModules.some(
        (addedModule) => addedModule.moduleId === module.moduleId && !addedModule.hidden,
      );
    });
  };

  const handleCourseWithCompletedModules = async () => {
    if (course.assessmentStatus === MODULE_PROGRESS_STATUS.COMPLETED) {
      // in this case the summative assessment is Done so we're gonna show the first module available
      openModule(course.modules[0]);
    } else if (course.assessmentStatus === MODULE_PROGRESS_STATUS.IN_PROGRESS) {
      // if the summative assessment is in progress, open it
      openSummativeAssessment();
    } else {
      const quizData = await getSummativeAssessment();
      if (quizData?.statusCode === 201 && quizData.data.assessment.length > 0) {
        // if the summative assessment is not started but exists, open it
        openSummativeAssessment();
      } else {
        // in this case there's no summative assessment so we're gonna show the first module available
        openModule(course.modules[0]);
      }
    }
  };

  const handleAddUserCourse = async (start = false): Promise<void> => {
    if (isCourseAdded) {
      const missingModules = getMissingModules();
      if (!missingModules?.length) return;
      await addRemainingCourseModules({ courseId: course.courseId, modules: missingModules });
    } else {
      await addCourseToUser({ courseId: course.courseId, modules: course.modules, start });
      openSuccessNotification(isSmallMobile ? 'Course added to your Board.' : `“${course.name}” was added to your Board.`);
    }
  };

  const startCourse = async () => {
    if (!isCourseAdded) {
      await handleAddUserCourse(true);

      if (!isAnyCourseModuleAdded && course.modules?.[0]) {
        openModule(course.modules[0]);

        return;
      }
    }

    if (!course.modules[0]) return;

    const getModuleByStatus = (status: MODULE_PROGRESS_STATUS) => {
      return course.modules.find(
        (mod) => mod.status === status && userModules.some((userModule) => userModule.moduleId === mod.moduleId),
      );
    };

    let chosenModule = getModuleByStatus(MODULE_PROGRESS_STATUS.IN_PROGRESS);
    if (chosenModule) {
      // open the first module in Doing
      openModule(chosenModule);
    } else {
      chosenModule = getModuleByStatus(MODULE_PROGRESS_STATUS.NOT_STARTED);
      if (chosenModule) {
        // if we're opening a module in To Do, we have to change its status to Doing
        await updateCourseModuleStatus({
          moduleId: chosenModule.moduleId,
          status: MODULE_PROGRESS_STATUS.IN_PROGRESS,
          hasQuiz: true,
          resetLessons: true,
          courseId: course.courseId,
        });

        openModule(chosenModule);
      } else {
        // all modules are in Done here
        await handleCourseWithCompletedModules();
      }
    }
  };

  return { startCourse, handleAddUserCourse, isCourseAdded };
};
